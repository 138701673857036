import React from 'react';
import { Link, graphql, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
// eslint-disable-next-line no-unused-vars
import tw, { styled } from 'twin.macro';

import useCompanyInfo from '../../hooks/use-company-info';
import useFloatTrips from '../../hooks/use-float-trips';
import useWadeTrips from '../../hooks/use-wade-trips';
import { ImageSlide } from '../../entities/image-slide';
import PageTemplate from '../../components/page-template';
import GuideSubNav from '../../components/guide-sub-nav';
import TripCard from '../../components/trip-card';
import SEO from '../../components/seo';
import ImageSlider from '../../components/image-slider';
import {
  FlexContainer,
  PageSection,
  TextContainer,
  TitleBar,
  PageTitle,
  SectionTitle,
} from '../../components/ui-elements';

const CalloutContainer = styled.div`
  ${tw`text-center bg-primary-300 text-primary-100 py-4 md:mb-8`}
`;
const BlockContainer = styled.div`
  ${tw`mb-0 text-lg`}
  & > ul, & > div > ul {
    ${tw`list-disc list-inside`}
  }
`;
const ColOneThird = styled.div`
  ${tw`w-full mb-8 md:w-1/3`}
`;

type GraphQLData = {
  page: {
    seo: {
      title: string;
      description: string;
      keywords: string[];
    };
    _rawBody: string;
    _rawBookCancelPolicy: string;
    _rawWhatToBring: string;
    coLicenseUrl: string;
    lightbox: {
      lightboxDisplay: string;
      slides: ImageSlide[];
    };
  };
};

type GuidedTripsPageProps = PageProps<GraphQLData>;

function GuidedTripsPage(props: GuidedTripsPageProps) {
  const { data } = props;
  const {
    page: {
      seo,
      _rawBody,
      _rawBookCancelPolicy,
      _rawWhatToBring,
      coLicenseUrl,
      lightbox,
    },
  } = data;
  const { phoneNum } = useCompanyInfo();
  const { floatTripInfo, fullDayFloat, halfDayFloat } = useFloatTrips();
  const floatTripImage = getImage(floatTripInfo.image.asset);
  const { wadeTripInfo, fullDayWade, halfDayWade } = useWadeTrips();
  const wadeTripImage = getImage(wadeTripInfo.image.asset);

  return (
    <PageTemplate>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <TitleBar>
        <PageTitle tw="text-primary-100">{seo.title}</PageTitle>
      </TitleBar>
      <GuideSubNav activeLink="overview" />
      <PageSection tw="md:mt-8">
        <FlexContainer>
          <TextContainer tw="md:leading-relaxed md:mr-8">
            <BlockContent blocks={_rawBody} />
          </TextContainer>
          <ImageSlider lightbox={lightbox} interval={4000} size="1/2" />
        </FlexContainer>
      </PageSection>
      <PageSection>
        <FlexContainer>
          <div tw="py-8 md:w-1/2 md:pr-8">
            <SectionTitle>Reservations/Cancellation</SectionTitle>
            <TextContainer tw="mb-0">
              <BlockContent blocks={_rawBookCancelPolicy} />
            </TextContainer>
            <div tw="bg-primary-200 p-4 rounded text-center text-base uppercase">
              <p tw="mb-1">Purchase your Colorado fishing license online</p>
              <a
                href={coLicenseUrl}
                tw="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here to Learn More
              </a>
            </div>
          </div>
          <div tw="py-8 md:w-1/2 md:pl-8">
            <SectionTitle>What to Bring</SectionTitle>
            <BlockContainer>
              <BlockContent blocks={_rawWhatToBring} />
            </BlockContainer>
          </div>
        </FlexContainer>
      </PageSection>
      <CalloutContainer>
        <PageTitle tw="md:text-center text-primary-100 pb-0">
          Call to Book a Trip
        </PageTitle>
        <a href={`tel:${phoneNum}`} tw="text-2xl">
          {phoneNum}
        </a>
      </CalloutContainer>
      <PageSection>
        <PageTitle tw="mt-4 md:text-center">{floatTripInfo.title}</PageTitle>
        <FlexContainer tw="">
          <ColOneThird tw="md:pr-8">
            {floatTripImage && (
              <GatsbyImage
                image={floatTripImage}
                alt={floatTripInfo.image.alt}
                tw="rounded mb-8"
              />
            )}
            <TextContainer tw="mb-0">
              <BlockContent blocks={floatTripInfo._rawBody} />
            </TextContainer>
            <Link to="float-trips/" tw="underline">
              Float Trip Information
            </Link>
          </ColOneThird>
          <ColOneThird tw="md:pr-8">
            <TripCard
              title="half day float"
              tripDuration={halfDayFloat.tripDuration}
              included={halfDayFloat.included}
              excluded={halfDayFloat.excluded}
              pricing={halfDayFloat.pricing}
            />
          </ColOneThird>
          <ColOneThird tw="md:pl-8">
            <TripCard
              title="full day float"
              tripDuration={fullDayFloat.tripDuration}
              included={fullDayFloat.included}
              excluded={fullDayFloat.excluded}
              pricing={fullDayFloat.pricing}
            />
          </ColOneThird>
        </FlexContainer>
      </PageSection>
      <PageSection>
        <PageTitle tw="md:text-center">{wadeTripInfo.title}</PageTitle>
        <FlexContainer>
          <ColOneThird tw="mb-8 md:pr-8">
            {wadeTripImage && (
              <GatsbyImage
                image={wadeTripImage}
                alt={wadeTripInfo.image.alt}
                tw="rounded mb-8"
              />
            )}
            <TextContainer tw="mb-0">
              <BlockContent blocks={wadeTripInfo._rawBody} />
            </TextContainer>
            <Link to="wade-trips/" tw="underline">
              Wade Trip Information
            </Link>
          </ColOneThird>
          <ColOneThird tw="md:pr-8">
            <TripCard
              title="half day wade"
              tripDuration={halfDayWade.tripDuration}
              included={halfDayWade.included}
              excluded={halfDayWade.excluded}
              pricing={halfDayWade.pricing}
            />
          </ColOneThird>
          <ColOneThird tw="md:pl-8">
            <TripCard
              title="full day wade"
              tripDuration={fullDayWade.tripDuration}
              included={fullDayWade.included}
              excluded={fullDayWade.excluded}
              pricing={fullDayWade.pricing}
            />
          </ColOneThird>
        </FlexContainer>
      </PageSection>
      {/* <PageSection>
        <PageTitle tw="md:text-center">Additional Trips</PageTitle>
        <FlexContainer>
          <div tw="w-1/3">
            <SectionTitle tw="md:text-center">Private Water</SectionTitle>
          </div>
          <div tw="w-1/3">
            <SectionTitle tw="md:text-center">Cast and Blast</SectionTitle>
          </div>
          <div tw="w-1/3">
            <SectionTitle tw="md:text-center">Tenkara</SectionTitle>
          </div>
        </FlexContainer>
      </PageSection> */}
    </PageTemplate>
  );
}

export default GuidedTripsPage;

export const query = graphql`
  query GuidedTripsPageQuery {
    page: sanityGuidedTripsPage {
      seo {
        title
        description
        keywords
      }
      _rawBody
      _rawBookCancelPolicy
      _rawWhatToBring
      coLicenseUrl
      lightbox {
        lightboxDisplay
        slides {
          _key
          alt
          caption
          asset {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
  }
`;
