import { useStaticQuery, graphql } from 'gatsby';
import { ImageSlide } from '../entities/image-slide';

type Pricing = {
  tripName: string;
  tripPrice: number;
  _key: string;
};

type FloatTrip = {
  floatTripInfo: {
    title: string;
    _rawBody: string;
    image: ImageSlide;
  };
  fullDayFloat: {
    tripDuration: number;
    included: string[];
    excluded: string[];
    pricing: Pricing[];
  };
  halfDayFloat: {
    tripDuration: number;
    included: string[];
    excluded: string[];
    pricing: Pricing[];
  };
};

const useFloatTrips = (): FloatTrip => {
  const data = useStaticQuery(graphql`
    query {
      floatTrips: sanityFloatTrips {
        floatTripInfo {
          title
          _rawBody
          image {
            _key
            alt
            caption
            asset {
              gatsbyImageData(width: 375)
            }
          }
        }
        fullDayFloat {
          tripDuration
          included
          excluded
          pricing {
            _key
            tripName
            tripPrice
          }
        }
        halfDayFloat {
          tripDuration
          included
          excluded
          pricing {
            _key
            tripName
            tripPrice
          }
        }
      }
    }
  `);

  return data.floatTrips;
};

export default useFloatTrips;
