import { useStaticQuery, graphql } from 'gatsby';
import { ImageSlide } from '../entities/image-slide';

type Pricing = {
  tripName: string;
  tripPrice: number;
  _key: string;
};

type WadeTrip = {
  wadeTripInfo: {
    title: string;
    _rawBody: string;
    image: ImageSlide;
  };
  fullDayWade: {
    tripDuration: number;
    included: string[];
    excluded: string[];
    pricing: Pricing[];
  };
  halfDayWade: {
    tripDuration: number;
    included: string[];
    excluded: string[];
    pricing: Pricing[];
  };
};

const useWadeTrips = (): WadeTrip => {
  const data = useStaticQuery(graphql`
    query {
      wadeTrips: sanityWadeTrips {
        wadeTripInfo {
          title
          _rawBody
          image {
            _key
            alt
            caption
            asset {
              gatsbyImageData(width: 375)
            }
          }
        }
        fullDayWade {
          tripDuration
          included
          excluded
          pricing {
            _key
            tripName
            tripPrice
          }
        }
        halfDayWade {
          tripDuration
          included
          excluded
          pricing {
            _key
            tripName
            tripPrice
          }
        }
      }
    }
  `);

  return data.wadeTrips;
};

export default useWadeTrips;
